import React, { ReactNode } from 'react';
import './CardComponent.css'
import './assets/font/fonts.css'
interface CardProps {
    icon: ReactNode;
    textHeader: string;
    text: string;
}

const CardComponent: React.FC<CardProps> = ({ icon, textHeader, text }) => {
    return (
        <div className="card-container h-75" >
            <div className="card-icon">
                {icon}
            </div>
            <div className="card-text-header">
                {textHeader}
            </div>
            <div className="card-text">
                {text}
            </div>
        </div>
    );
}

export default CardComponent;

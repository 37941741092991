import {useFetch, useFetchMarkdown} from "./utilities"

const BASE_URL = "https://patient-services-preprod.dokitari.com"

export type FeedType = {
    id: string,
    title: string,
    description: string,
    videoUrl: string,
    thumbnailUrl: string,
    section: string,
    type: string,
    dateCreated: string,
    content: any,
    author: string,
    userId: string,
    status: string,
    tags: [
        string
    ],
    errorMessage: string,
    likes: number,
    dislikes: number
}
export const GetFeeds = () => {
    const url = `${BASE_URL}/feeds`;
    const {data,isLoading,error} = useFetch(url)
    const feeds = data as FeedType[] ?? []
    return {feeds,isLoading,error}
}
export const GetFeed = (id: string | undefined) => {
    const url = `${BASE_URL}/feeds/${id}`;
    const {data,isLoading,error} = useFetch(url)
    const feed = data as FeedType
    return {feed,isLoading,error}

}
export const GetMarkdown = (id: string | undefined) => {
    const url = `${BASE_URL}/feeds/${id}/description`;
    const {data,isLoading,error} = useFetchMarkdown(url)
    const feedContent = data as string
    return {feedContent,isLoading,error}
}
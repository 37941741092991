import * as React from "react"
import { SVGProps } from "react"
const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#979CAC"
            d="m22.55 17.048-3.534-.403a2.77 2.77 0 0 0-2.283.793l-2.56 2.56A20.938 20.938 0 0 1 5 10.829l2.575-2.575a2.77 2.77 0 0 0 .793-2.283l-.403-3.507A2.785 2.785 0 0 0 5.196 0H2.788C1.216 0-.092 1.308.005 2.88.743 14.767 10.248 24.258 22.12 24.996c1.573.097 2.88-1.21 2.88-2.784v-2.407c.015-1.406-1.043-2.589-2.448-2.756Z"
        />
    </svg>
)
export default PhoneIcon

import * as React from "react"
import { SVGProps } from "react"
const IconForDrawer = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={29}
        height={23}
        fill="none"
        {...props}
    >
        <path
            stroke="#015AFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3.125}
            d="M2 2h25M6.687 11.375H27M11.375 20.75H27"
        />
    </svg>
)
export default IconForDrawer

import React, {useEffect, useRef, useState} from 'react';
import './ReasonSection.css'
import DokitariIntroduce from "./assets/images/DokitariIntroduce.png";
import UserIcon from "./assets/svg/UserIcon";
import InputWithIcon from "./InputContainer";
import MailIcon from "./assets/svg/MailIcon";
import PhoneIcon from "./assets/svg/PhoneIcon";
import {getDeepLinkURL} from "./AppDownloadLink";
import AppStoreImage from "./assets/svg/AppStoreImage";
import PlayStoreImage from "./assets/svg/PlayStoreImage";
import {UseSendMessage} from "./apis/SendMessage";
import {Oval} from "react-loader-spinner";


const GetInTouch = () => {

    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [phoneNumber,setPhoneNumber] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false);


    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // reset the height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [message]);

    const payload = {
        fullName,
        email,
        phoneNumber,
        message
    }
    const handlePayload = async () => {
        setIsSubmitting(true);
        try {
            await UseSendMessage(payload);
        } catch (error) {
            console.error("An error occurred when sending feedback:", error);
        }
        setIsSubmitting(false);
    }

    return (
        <div className={"px-8 mb-10"}  id={'getInTouch'}>
                <div className={" grid grid-cols-1 lg:grid-cols-2 lg:gap-10"}>
                    <div className={"flex flex-col justify-between"}>
                        <div className={"mt-10"}>
                            <InputWithIcon
                                icon={<UserIcon/>}
                                placeholder="Full Name"
                                name="fullName"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            <InputWithIcon
                                icon={<MailIcon/>}
                                placeholder="Email"
                                name="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <InputWithIcon
                                icon={<PhoneIcon/>}
                                placeholder="Phone number"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <div className={`input-parent `} style={{paddingTop:15}}>
                        <textarea
                            placeholder={"Write your message here ..."}
                            className={"message-container"}
                            value={message}
                            onChange={(e)=> setMessage(e.target.value)}>
                        </textarea>
                            </div>

                        </div>

                        <div className={"text-center lg:text-start"}>
                            <button className={"buttonConsult "} disabled={email.length ===0 && phoneNumber.length ===0 && fullName.length ===0} onClick={handlePayload}>
                                <div className={"basicText "} style={{margin:0,color:'#FFFFFF'}}>
                                    {isSubmitting ? <Oval color={'#FFF'} secondaryColor={'#adadad'} width={148} height={27}/> : 'Send a Message'  }
                                </div>
                            </button>
                        </div>
                    </div>

                    <div className={"content-center text-center self-center image-content"}>
                        <img src={DokitariIntroduce} alt={'Doctors Illustration'}/>
                        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                            <a href={getDeepLinkURL()} target="_blank" rel="noopener noreferrer">
                                <AppStoreImage/>
                            </a>
                            <a href={getDeepLinkURL()} target="_blank" rel="noopener noreferrer">
                                <PlayStoreImage/>
                            </a>
                        </div>
                    </div>
                </div>

        </div>
    );
};

export default GetInTouch;
import React from 'react';
import './index.css'
const AboutTheApp = () => {
    return (
        <div className={"flex flex-col main"}>
            <div className={" page-header px-9 py-8"} style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}}>
                <h1 className={"text-center"}>ABOUT THE APP</h1>
            </div>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-start-3 col-span-8 p-4 text-justify">

                <h2 className={"title-text"}>
                    Introducing Dokitari Health App
                </h2>
                <div className={"text-justify"}>
                    <div className={"default-text"}>
                        Welcome to Dokitari Health App, your trusted companion for all your healthcare needs. Our mission is to provide you with convenient and comprehensive healthcare solutions, right at your fingertips.
                        <div>
                            With Dokitari Health App, you can:
                        </div>
                    </div>
                    <h4 className={"default-text"}> <span className={"header-text"}>1. Consult with Expert Doctors Online: </span>
                        Access top-notch medical expertise from the comfort of your home. Connect with experienced healthcare professionals for virtual consultations, second opinions, and medical advice whenever you need it </h4>
                    <h4 className={"default-text"}> <span className={"header-text"}>2. Effortlessly Book Hospital Appointments: </span>
                        Say goodbye to long waiting times. Schedule appointments at your preferred healthcare facilities with just a few taps, ensuring you receive timely care.
                    </h4>

                    <h4 className={"default-text"}> <span className={"header-text"}>3. Stay Informed with Health Articles: </span>
                        Explore a vast library of informative health articles, keeping you up-to-date on the latest medical trends, wellness tips, and lifestyle choices for a healthier you.
                    </h4>
                    <div className={"default-text"}> <div className={"mb-5"}>
                        <span className={"header-text"}>4. Securely Manage Your Medical Records: </span>
                        Safeguard your health history with our encrypted and user-friendly platform. Store and access your medical records, test results, and prescriptions securely in one place.
                    </div>
                        <div className={"default-text"}><div className={"mb-5"}>Dokitari Health App is committed to making healthcare accessible, efficient, and user-centric. Join us on this journey toward better health and well-being. Download the app today and take control of your healthcare experience. Your health, your way.</div>
                            <div>Your well-being is our priority. Dokitari Health App - Your Health, Your Choice.</div></div>

                    </div>
                </div>
                </div>
            </div>
        </div>

    );
};



export default AboutTheApp;
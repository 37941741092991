import React from 'react';
import './ReasonSection.css'
import './CardComponent.css'
interface PhotoCardProps {
    photoSrc: string;
    textHeader: string;
    text: string;
    date:string;
    height?:number
}

const PhotoCardComponent: React.FC<PhotoCardProps> = ({ photoSrc, textHeader, text,date }) => {
    return (
        <div className="photo-card-container  mx-3 mb-5">
                <img src={photoSrc} alt="Card Top" className="rounded-t-lg" />
            <div className={"p-4 flex flex-col justify-between"}>
                <div className="card-text-header text-md font-bold">
                    {textHeader}
                </div>
                <div className="card-text text-sm mt-1">
                    {text}
                </div>
                <div className={"card-date text-xs"}>{date}</div>
            </div>

        </div>
    );
}

export default PhotoCardComponent;

import React from 'react';
import './Footer.css';
import DokitariFooterLogo from "./assets/svg/DokitariFooterLogo";
import DokitariFooterLogoMd from "./assets/svg/DokitariFooterLogoMd";

interface SocialLinkProps {
    icon: React.ReactElement;
    link: string;
}

interface ColumnProps {
    header: string;
    links: { text: string; link: string }[];
}

const SocialLink: React.FC<SocialLinkProps> = ({ icon, link }) => (
    <a href={link} className="social-icon">
        {icon}
    </a>
);

const Column3Line: React.FC<ColumnProps> = ({ header, links }) => (
    <div className="grid grid-rows-3 gap-4 mb-5 lg:text-center">
        <p className={"header-column"} style={{fontWeight:700}}>{header}</p>
        <a href={links[0].link} className="text-column">{links[0].text}</a>
        <a href={links[1].link} className="text-column">{links[1].text}</a>
        <a href={links[2].link} className="text-column">{links[2].text}</a>
    </div>
);
const Column: React.FC<ColumnProps> = ({ header, links }) => (
    <div className="grid grid-rows-3 gap-4 mb-5 lg:text-center">
        <p className={"header-column"} style={{fontWeight:700}}>{header}</p>
        <a href={links[0].link} className="text-column">{links[0].text}</a>
    </div>
);

interface FooterProps {
    facebookIcon: React.ReactElement;
    facebookLink: string;
    instagramIcon: React.ReactElement;
    instagramLink: string;
    linkedInIcon: React.ReactElement;
    linkedInLink: string;
}

const   FooterComponent: React.FC<FooterProps> = ({  facebookIcon, facebookLink, instagramIcon, instagramLink,linkedInLink,linkedInIcon }) => {
    return (
        <footer className="footer-container grid grid-cols-1 lg:grid-cols-3 px-8 py-5">
            <div className="footer-column" style={{alignItems:"start"}}>
                <a href={'/'} className="logo-container">
                    <DokitariFooterLogo className={"hidden xl:block"}/>
                    <DokitariFooterLogoMd className={"block xl:hidden"}/>
                </a>
                <div className="social-links">
                    <SocialLink icon={facebookIcon} link={facebookLink} />
                    <SocialLink icon={instagramIcon} link={instagramLink} />
                    <SocialLink icon={linkedInIcon} link={linkedInLink} />

                </div>
            </div>

            <Column3Line  header="Support" links={[{ text: "Privacy Policy", link: "/PrivacyPolicy" }, { text: "User Agreement", link: "/UserAgreement" }, { text: "About the App", link: "/AboutTheApp" }]} />
            <Column header="Contacts" links={[{ text: "Contact Us", link: "/ContactUs" }]} />

        </footer>
    );
}

export default FooterComponent;

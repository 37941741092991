import * as React from "react"
import { SVGProps } from "react"
const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={86}
        height={98}
        fill="none"
        {...props}
    >
        <rect width={86} height={98} fill="#FCF8EE" rx={15} />
        <path
            fill="#F5B609"
            d="M34 29.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 0 3h-15a1.5 1.5 0 0 1-1.5-1.5ZM29.5 37a1.5 1.5 0 0 0 0 3h27a1.5 1.5 0 0 0 0-3h-27ZM28 44.5a1.5 1.5 0 0 1 1.5-1.5h27a1.5 1.5 0 0 1 0 3h-27a1.5 1.5 0 0 1-1.5-1.5ZM29.5 49a1.5 1.5 0 0 0 0 3h27a1.5 1.5 0 0 0 0-3h-27Z"
        />
        <path
            fill="#F5B609"
            fillRule="evenodd"
            d="M64 67a6 6 0 0 1-6 6h-4.5v6L49 76.75 44.5 79v-6H28a6 6 0 0 1-6-6V25a6 6 0 0 1 6-6h30a6 6 0 0 1 6 6v42ZM28 22a3 3 0 0 0-3 3v42a3 3 0 0 0 3 3h16.5v-3.531a6 6 0 1 1 9 0V70H58a3 3 0 0 0 3-3V25a3 3 0 0 0-3-3H28Zm22.5 46.311a6.015 6.015 0 0 1-3 0v5.835l1.5-.75 1.5.75V68.31ZM49 65.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            clipRule="evenodd"
        />
    </svg>
)
export default DocumentIcon

import * as React from 'react';

export function getDeepLinkURL() {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return 'https://play.google.com/store/apps/details?id=com.dokitari.patientdokitari&hl=en';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'https://apps.apple.com/ph/app/dokitari-health/id1543698473';
    }
    return 'https://play.google.com/store/apps/details?id=com.dokitari.patientdokitari&hl=en';
}



import * as React from "react"
import { SVGProps } from "react"
const UserIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#979CAC"
            d="M12.5 12.5c-1.719 0-3.19-.612-4.414-1.836C6.862 9.44 6.25 7.97 6.25 6.25c0-1.719.612-3.19 1.836-4.414C9.31.612 10.78 0 12.5 0c1.719 0 3.19.612 4.414 1.836C18.138 3.06 18.75 4.53 18.75 6.25c0 1.719-.612 3.19-1.836 4.414C15.69 11.888 14.22 12.5 12.5 12.5ZM3.125 25a3.01 3.01 0 0 1-2.208-.919A3.003 3.003 0 0 1 0 21.875v-1.25c0-.885.228-1.7.684-2.442A4.552 4.552 0 0 1 2.5 16.484a23.23 23.23 0 0 1 4.922-1.817 21.416 21.416 0 0 1 5.078-.604c1.719 0 3.412.202 5.078.606a23.279 23.279 0 0 1 4.922 1.815 4.56 4.56 0 0 1 1.817 1.7c.456.743.684 1.557.683 2.441v1.25a3.01 3.01 0 0 1-.919 2.208 3.003 3.003 0 0 1-2.206.917H3.125Z"
        />
    </svg>
)
export default UserIcon

import * as React from "react"
import { SVGProps } from "react"
const DislikeButton = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        fill="none"
        {...props}
    >
        <path
            fill="#979CAC"
            d="M3 0h1.25v16.25H3a2.5 2.5 0 0 1-2.5-2.5V2.5A2.5 2.5 0 0 1 3 0Zm20 16.25h-8.75l1.403 4.21a2.5 2.5 0 0 1-2.372 3.291h-.28l-6.25-6.798V.001H20.5l4.89 10.745.11.505v2.5a2.5 2.5 0 0 1-2.5 2.5Z"
        />
    </svg>
)
export default DislikeButton

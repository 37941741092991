import * as React from "react"
import { SVGProps } from "react"
const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M21.923 0H8.077A8.086 8.086 0 0 0 0 8.077v13.846A8.086 8.086 0 0 0 8.077 30h13.846A8.086 8.086 0 0 0 30 21.923V8.077A8.086 8.086 0 0 0 21.923 0ZM15 21.923A6.923 6.923 0 1 1 21.923 15 6.93 6.93 0 0 1 15 21.923Zm8.654-13.846a1.73 1.73 0 1 1 0-3.461 1.73 1.73 0 0 1 0 3.46ZM19.615 15a4.616 4.616 0 1 1-9.231 0 4.616 4.616 0 0 1 9.231 0Z"
        />
        <mask id="a" fill="#fff">
            <rect width={30} height={30} rx={2} />
        </mask>
        <rect
            width={30}
            height={30}
            stroke="#fff"
            strokeWidth={6}
            mask="url(#a)"
            rx={2}
        />
    </svg>
)
export default InstagramIcon

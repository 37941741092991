import React from 'react';

const Aup = () => {
    return (
        <div>
            <div className={"flex flex-col main"}>
                <div className={" page-header px-9 py-8"} style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}}>
                    <h1 className={"text-center"}>ACCEPTABLE USE POLİCY</h1>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-start-3 col-span-8 p-4 text-justify">
                        <div className="col-md-12">
                            <p className={"mb-2"}>We hope you love using Dokitari. We really do. This document contains important
                                information that
                                you
                                need to consider before making an important decision.</p>
                                <i>
                                    <p className={"mb-2"}>Effective Date: March 01, 2019</p>
                                </i>
                                
                                    <p className={"mb-2"}>Here at Dokitari, we’re committed to making our service safe, caring, and
                                        accessible for
                                        everyone. That’s why we created this document, which explains the rules for
                                        using Dokitari.
                                        Thank you for reading, and for helping us deliver a better healthcare
                                        experience!
                                            This Acceptable Use Policy (“AUP”) is incorporated by reference into
                                            Dokitari mobile app’s
                                            (“Dokitari,” “we,” or “us”) Terms of Use and governs your use of the Site
                                            and Services (as such
                                            terms are defined in the Terms of Use). Any capitalized term used but not
                                            defined in this AUP
                                            shall have the meaning in our Terms of Use. BY USING OR OTHERWISE ACCESSING
                                            THE SERVICES, AND/OR
                                            BY REGISTERING WITH US, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ THIS
                                            AUP, UNDERSTAND ITS
                                            TERMS, AND AGREE TO BE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE WITH
                                            THIS AUP, YOU CANNOT
                                            USE THE SERVICES.
                                    </p>
                                    
                                        <p className={"font-bold text-xl mb-2"}>1. RESTRICTED USES</p>
                                        <p className={"mb-2"}> You agree not to use the Services in any unlawful, infringing, tortious or
                                            harmful manner; in
                                            any way that violates another party's intellectual property, privacy or
                                            other rights; or in any
                                            way that otherwise interferes with the operation, use or enjoyment of any
                                            service, system or
                                            other property.
                                            Without limiting any of the foregoing, you agree that you shall not (and you
                                            agree not to allow
                                            any third party to):
                                        </p>
                                        <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                            <li>Copy, modify, adapt, translate, or reverse engineer any Content (as
                                                defined in the Terms
                                                of Use) or portion of the Site, its content or materials and/or the
                                                Services;
                                            </li>
                                            <li>Remove any copyright, trademark or other proprietary rights notices
                                                contained in or on
                                                the Site and/or the Services or in or on any Content;
                                            </li>
                                            <li>Use any robot, spider, site search/retrieval application, or other
                                                automated device,
                                                process or means to access, retrieve or index any portion of the Site
                                                and/or the
                                                Services;
                                            </li>
                                            <li>Access or retrieve any Content or portion of the Site and/or the
                                                Services for purposes
                                                of constructing or populating a searchable database of reviews or other
                                                Content related
                                                to the healthcare industry or Healthcare Providers (as defined in the
                                                Terms of Use);
                                            </li>
                                            <li>Reformat or frame any Content or portion of the web pages that are part
                                                of the Site
                                                and/or the Services;
                                            </li>
                                            <li>Fraudulently or intentionally misuse the Services, including without
                                                limitation
                                                scheduling an appointment with a Healthcare Provider which you do not
                                                intend to keep, or
                                                scheduling an appointment for Designated Provider Services (as defined
                                                in the Additional
                                                Terms) which you do not intend to pay using the Transaction Processing
                                                Services (as
                                                defined in the Additional Terms);
                                            </li>
                                            <li>Create user accounts, book appointments or submit Posted Information by
                                                automated means
                                                or under false or fraudulent pretences;
                                            </li>
                                            <li>Collect or store personal data about other users in connection with the
                                                prohibited
                                                activities described in this paragraph; or
                                            </li>
                                            <li>Use any means, including software means, to conduct web scraping of any
                                                portion of the
                                                Site, its content or materials and/or the Services.
                                            </li>
                                        </ul>
                                        <p className={"mb-2"}></p>
                            <p className={"mb-2 font-bold text-lg"}>No Spam.</p>
                                        <p className={"mb-2"}>You may not use contact information provided by our users or Healthcare
                                            Providers, or harvest
                                            such information for the purpose of sending, or to facilitate the sending
                                            of, unsolicited bulk
                                            communications, such as SPAM. You may not allow others to use your account
                                            to violate the terms
                                            of this section. We may immediately terminate your registration or access to
                                            the Services and
                                            take other legal action if you or anyone using your Credentials violates
                                            these provisions.</p>
                                        <p className={"font-bold text-xl mb-2"}>2. USER CONTENT</p>
                                        <p className={"mb-2"}>Pursuant to the <a href="https://dokitari.com/tos.php">Terms of Use</a>, you
                                            may submit feedback,
                                            submit inquiries and to participate in the
                                            other interactive and community features of the Site (collectively “Posted
                                            Information”). In
                                            providing feedback, please give clear, honest information about the
                                            Healthcare Provider and your
                                            experiences, but do not use inappropriate language, make gratuitous personal
                                            criticisms or
                                            comments or provide information that others could use to determine your
                                            identity. When
                                            participating in interactive or community aspects of the Service, please do
                                            not post any
                                            information that another user or Healthcare Provider may use to individually
                                            identify you, but
                                            please do include all relevant information in a concise manner to help us
                                            provide you with a
                                            helpful response.
                                            
                                                We reserve the right to publish your Posted Information as part of the
                                                Services and to also
                                                remove your Posted Information for any reason. We are not, however,
                                                responsible for any failure
                                                or delay in posting or removing Posted Information. Keep in mind that
                                                the Posted Information of
                                                others is simply opinion and should not be relied on.
                                                Without limiting the generality of the foregoing:
                                        </p>
                                        <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                            <li>You are solely responsible for any Posted Information that you submit,
                                                publish or
                                                display through the Services or transmit to other members and/or other
                                                users of the
                                                Site.
                                            </li>
                                            <li>You may not post, distribute, or reproduce in any way any copyrighted
                                                material,
                                                trademarks, or other proprietary information without obtaining the prior
                                                written consent
                                                of the owner of such proprietary rights. You may not submit any content
                                                or material that
                                                infringes, misappropriates or violates the intellectual property,
                                                publicity, privacy or
                                                other rights of any party.
                                            </li>
                                            <li>You may not provide any Posted Information that falsely expresses or
                                                implies that such
                                                content or material is sponsored or endorsed by Dokitari mobile Health.
                                            </li>
                                            <li>You may not provide any Posted Information that is unlawful or that
                                                promotes or
                                                encourages illegal activity.
                                            </li>
                                            <li>You understand and agree that Dokitari mobile Health may (but is not
                                                obligated to)
                                                review and delete any Posted Information that in the sole judgment of
                                                Dokitari mobile
                                                Health violates the Terms of Use or this AUP, or which might be
                                                offensive, illegal, or
                                                that might violate the rights of, harm, or threaten the safety of other
                                                users or members
                                                of the Services and/or other website users.
                                            </li>
                                            <li>You agree that you will only provide Posted Information that you believe
                                                to be true.
                                            </li>
                                            <li>You may not submit Posted Information that:
                                                <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                                    <li>Is false, deceptive or misleading;</li>
                                                    <li>Harasses or advocates harassment of another person;</li>
                                                    <li>Involves the transmission of unsolicited mass mailing or
                                                        "spamming";
                                                    </li>
                                                    <li>Violates, infringes or misappropriates the intellectual property
                                                        or other rights
                                                        of any person;
                                                    </li>
                                                    <li>Violates antitrust, competition, or consumer protection laws;
                                                    </li>
                                                    <li>Is threatening, abusive, obscene, defamatory or libelous; or
                                                    </li>
                                                    <li>Is pornographic or sexually explicit in nature.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        
                                            The foregoing is a partial list of the kind of content and communications
                                            that are illegal or
                                            prohibited on/through the Site. Dokitari mobile Health reserves the right to
                                            investigate and
                                            take appropriate legal action in accordance with the <a
                                            href="https://dokitari.com/tos.php">Terms of Use</a>.
                                            <p className={"mb-2"}></p>
                                            <p className={"font-bold text-xl mb-2"}>3. COPYRIGHT DISPUTE POLICY</p>
                                            <p className={"mb-2"}>Dokitari mobile Health adopts the general policy toward copyright
                                                infringement in accordance with
                                                THE COPYRIGHT AND NEIGHBORING RIGHTS ACT 2006 and THE COPYRIGHT AND
                                                NEIGHBORING RIGHTS
                                                REGULATIONS, 2010
                                                (https://ursb.go.ug/copyrights-acts-regulations/). The address of
                                                Dokitari mobile Health’s
                                                Designated Agent to Receive Notification of Claimed Infringement
                                                ("Designated Agent") is
                                                provided at the bottom of this section.
                                            </p>
                                            <h3>Dokitari mobile Health Policy.</h3>
                                            <p className={"mb-2"}>Our policy is to;
                                            </p>
                                            <ol>
                                                <li>Block access to or remove material that it believes in good faith to
                                                    be copyrighted
                                                    material that has been illegally copied and distributed by any of
                                                    our advertisers,
                                                    affiliates, content providers, members or other users; and
                                                </li>
                                                <li>Remove and discontinue Service to repeat offenders.</li>
                                            </ol>
                                            <p className={"mb-2"}></p>
                                            <h3>Procedure for Reporting Copyright Infringements.</h3>
                                            <p className={"mb-2"}>If you believe that material or content residing on or accessible through
                                                the Site or the
                                                Services infringes a copyright, please send a notice of copyright
                                                infringement containing the
                                                following information to the Designated Agent listed below ("Proper Bona
                                                Fide Infringement
                                                Notification"):
                                            </p>
                                            <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                                <li>A physical or electronic signature of a person authorized to act on
                                                    behalf of the owner
                                                    of the copyright that has been allegedly infringed;
                                                </li>
                                                <li>Identification of works or materials being infringed;</li>
                                                <li>Identification of the material that is claimed to be infringing
                                                    including information
                                                    regarding the location of the infringing materials that the
                                                    copyright owner seeks to
                                                    have removed, with sufficient detail so that Dokitari mobile Health
                                                    is capable of
                                                    finding and verifying its existence;
                                                </li>
                                                <li>Contact information about the notifier including address, telephone
                                                    number and, if
                                                    available, email address;
                                                </li>
                                                <li>A statement that the notifier has a good faith belief that the
                                                    material is not
                                                    authorized by the copyright owner, its agent, or the law; and
                                                </li>
                                                <li>A statement made under penalty of perjury that the information
                                                    provided is accurate and
                                                    the notifying party is authorized to make the complaint on behalf of
                                                    the copyright
                                                    owner.
                                                </li>
                                            </ul>
                                            <p className={"mb-2"}></p>
                                            <h3>Upon Receipt of a Bona Fide Infringement Notification.</h3>
                                            <p className={"mb-2"}>Once a Proper Bona Fide Infringement Notification is received by the
                                                Designated Agent, it is
                                                Dokitari mobile Health’s policy:
                                            </p>
                                            <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                                <li>to remove or disable access to the infringing material;</li>
                                                <li>to notify the content provider, member or user that it has removed
                                                    or disabled access to
                                                    the material; and
                                                </li>
                                                <li>for repeat offenders, to terminate such content provider's, member's
                                                    or user's access to
                                                    the Service.
                                                </li>
                                            </ul>
                                            <p className={"mb-2"}></p>
                                            <h3>Procedure to Supply a Counter-Notice to the Designated Agent.</h3>
                                            <p className={"mb-2"}>If the content provider, member or user believes that the material that
                                                was removed or to which
                                                access was disabled is either not infringing, or the content provider,
                                                member or user believes
                                                that it has the right to post and use such material from the copyright
                                                owner, the copyright
                                                owner's agent, or pursuant to the law, the content provider, member or
                                                user must send a
                                                counter-notice containing the following information to the Designated
                                                Agent listed below:
                                            </p>
                                            <ul className={" space-y-1  list-disc mb-2 list-inside"}>
                                                <li>A physical or electronic signature of the content provider, member
                                                    or user;
                                                </li>
                                                <li>Identification of the material that has been removed or to which
                                                    access has been
                                                    disabled and the location at which the material appeared before it
                                                    was removed or
                                                    disabled;
                                                </li>
                                                <li>A statement that the content provider, member or user has a good
                                                    faith belief that the
                                                    material was removed or disabled as a result of mistake or a
                                                    misidentification of the
                                                    material; and
                                                </li>
                                                <li>The content provider's, member's or user's name, address, telephone
                                                    number, and, if
                                                    available, email address and a statement that such person or entity
                                                    consents to the
                                                    jurisdiction of the Federal Court for the judicial district in which
                                                    the content
                                                    provider's, member's or user's address is located, or if the content
                                                    provider's,
                                                    member's or user's address is located outside the United States, for
                                                    any judicial
                                                    district in which Dokitari mobile Health is located, and that such
                                                    person or entity will
                                                    accept service of process from the person who provided notification
                                                    of the alleged
                                                    infringement.
                                                </li>
                                            </ul>
                                            <p className={"mb-2"}></p>
                                            <p className={"mb-2"}>If a counter-notice is received by the Designated Agent, Dokitari mobile
                                                Health may send a copy
                                                of the counter-notice to the original complaining party informing that
                                                person that it may
                                                replace the removed material or cease disabling it in 10 business days.
                                                Unless the copyright
                                                owner files an action seeking a court order against the content
                                                provider, member or user, the
                                                removed material may be replaced or access to it restored in 10 to 14
                                                business days or more
                                                after receipt of the counter-notice, at Dokitari mobile Health’s
                                                discretion.
                                                    Address for Designated Agent. Please contact Dokitari mobile
                                                    Health’s Designated Agent to
                                                    Receive Notification of Claimed Infringement at the following
                                                    address:
                                                    Copyright Agent, Wynn Heakth Group LTD.
                                            </p>
                                            <p className={"font-bold text-xl mb-2"}>4. UPDATES AND CHANGES TO THE ACCEPTABLE USE POLICY</p>
                                            <p className={"mb-2"}>The effective date of this Acceptable Use Policy is set forth at the top
                                                of this webpage. We will
                                                notify you of any material change by posting notice on this webpage.
                                                Your continued use of the
                                                Services after the effective date constitutes your acceptance of the
                                                amended Acceptable Use
                                                Policy. We encourage you to periodically review this page for the latest
                                                information on
                                                acceptable uses of the Services. The amended Acceptable Use Policy
                                                supersedes all previous
                                                versions. IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS ACCEPTABLE USE
                                                POLICY, YOU MUST STOP
                                                USING THE SERVICES AFTER THE EFFECTIVE DATE OF SUCH CHANGES.
                                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Aup;
import {useEffect, useState} from "react";

export const useFetch = (url: string) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<string>();
    useEffect(() => {
        console.log(url,"url before fetch")
        if (!url) return ;
        const fetchUrl = async () => {

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            try {

                const response = await fetch(url, requestOptions);
                console.log(response,"response line 22")
                if (response.status === 400) {
                    throw new Error("Bad Request");
                }
                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`);
                }
                const responseData = await response.json();

                setData(responseData);

            } catch (error: any) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUrl();
    }, [url]);
    return {data, isLoading, error};
};
export async function postFetch(url: string, body: any) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);

    console.log(response,"response util 55")
    if (response.status === 400) {
        throw new Error("Bad Request");
    }
    if (response.status === 401) {
        throw new Error("Unauthorized");
    }
    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }

    return await response.json();
}
export const useFetchMarkdown = (url: string) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<string>();
    useEffect(() => {
        console.log(url,"url before fetch")
        if (!url) return ;
        const fetchUrl = async () => {

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            try {

                const response = await fetch(url, requestOptions);
                console.log(response,"response line 22")
                if (response.status === 400) {
                    throw new Error("Bad Request");
                }
                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`);
                }
                const responseData = await response.text();
                setData(responseData);

            } catch (error: any) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUrl();
    }, [url]);
    return {data, isLoading, error};
};

import React from 'react';
import DokitariPreview from "./DokitariPreview";
import CardSection from "./CardSection";
import ReasonSection from "./ReasonSection";
import HowDokitariWorks from "./HowDokitariWorks";
import AboutUs from "./AboutUs";
import GetInTouch from "./GetInTouch";
import OurArticles from "./OurArticles";

const Home = () => {
    return (
        <>
            <DokitariPreview/>
            <CardSection/>
            <ReasonSection/>
            <HowDokitariWorks />
            <AboutUs />
            <div style={{background: 'linear-gradient(180deg, #F4F6F3 0%, #F6E2D7 52.5%, #F4F6F3 100%)'}} className={"headerText text-center lg:text-start px-8"}>
                <h2>GET IN <span style={{fontWeight:700}}>TOUCH</span></h2>
                <GetInTouch />
            </div>

            <OurArticles/>
        </>
    );
};

export default Home;

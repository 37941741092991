import * as React from "react"
import { SVGProps } from "react"
const MailIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={21}
        fill="none"
        {...props}
    >
        <path
            fill="#979CAC"
            d="M2.5 20.454c-.688 0-1.276-.25-1.766-.751A2.485 2.485 0 0 1 0 17.898V2.557C0 1.854.245 1.252.735.75A2.376 2.376 0 0 1 2.5 0h20c.688 0 1.276.25 1.766.752.49.5.735 1.103.734 1.805v15.34c0 .704-.245 1.306-.735 1.807a2.376 2.376 0 0 1-1.765.75h-20Zm10-9.172a1.304 1.304 0 0 0 .656-.192L22 5.433c.167-.106.292-.24.375-.4.083-.16.125-.336.125-.527 0-.426-.177-.745-.531-.958-.354-.213-.719-.203-1.094.032L12.5 8.949l-8.375-5.37c-.375-.234-.74-.239-1.094-.015-.354.224-.531.538-.531.942 0 .213.042.4.125.56.083.16.208.283.375.367l8.844 5.657a1.3 1.3 0 0 0 .656.192Z"
        />
    </svg>
)
export default MailIcon

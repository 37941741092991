import React from 'react';
import GetInTouch from "./GetInTouch";

const ContactUs = () => {
    return (
        <div className={"flex flex-col main"}>
            <div className={" page-header px-9 py-8"} style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}}>
                <h1 className={"text-left"}>CONTACT US</h1>
            </div>
            <div className={"mt-10"}>
                <GetInTouch/>
            </div>

        </div>
    );
};

export default ContactUs;

import React from 'react';
import './ReasonSection.css'

type InputProps = {
    icon: React.ReactNode; // Icon can be a React component
    placeholder: string;
    type?: string;
    name?: string;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const InputWithIcon: React.FC<InputProps> = ({ icon, placeholder, type = "text", name, value, onChange }) => {
    return (
        <div className="input-parent" style={{height:80}}>
            <div className="icon">
                {icon}
            </div>
            <input className={"input"} type={type} placeholder={placeholder} name={name} value={value} onChange={onChange} />
        </div>
    );
}

export default InputWithIcon;

import React, {useRef} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route, Routes,
} from 'react-router-dom';
import Header from "./Header";
import FooterComponent from "./Footer";
import FacebookIcon from "./assets/svg/FacebookIcon";
import InstagramIcon from "./assets/svg/InstagramIcon";
import LinkedInIcon from "./assets/svg/LinkedInIcon";
import Home from "./Home";
import AboutTheApp from "./AboutTheApp";
import PrivacyPolicy from "./PrivacyPolicy";
import UserAgreement from "./UserAgreement";
import AUP from "./AUP";
import ContactUs from "./ContactUs";
import Article from "./Article";

const App: React.FC = () => {
    const aboutUsRef = useRef<HTMLDivElement>(null);
    const getInTouchRef = useRef<HTMLDivElement>(null);
    console.log("ref check \n",aboutUsRef, getInTouchRef); // Debug line: check the refs in console.
    const scrollToSection = (section: string) => {
        console.log(aboutUsRef, getInTouchRef); // Debug line: check the refs in console.

        if (section === 'aboutUs' && aboutUsRef.current) {
            aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'getInTouch' && getInTouchRef.current) {
            getInTouchRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Router>
            <div className="App">
                <Header scrollToSection={scrollToSection}/>
                <div className={"contents"}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path ="/AboutTheApp" element = {<AboutTheApp/>}/>
                        <Route path ="/PrivacyPolicy" element = {<PrivacyPolicy/>}/>
                        <Route path ="/UserAgreement" element = {<UserAgreement/>}/>
                        <Route path ="/AUP" element = {<AUP/>}/>
                        <Route path ="/ContactUs" element = {<ContactUs/>}/>
                        <Route path ="/Articles/:id" element = {<Article/>}/>
                    </Routes>
                    <FooterComponent
                        facebookIcon={<FacebookIcon />}
                        facebookLink="https://www.facebook.com/dokitari/"
                        instagramIcon={<InstagramIcon />}
                        instagramLink="https://www.instagram.com/hellodoktari/?hl=en"
                        linkedInIcon={<LinkedInIcon/>}
                        linkedInLink="https://www.linkedin.com/company/dokitari-health/about/"
                    />
                </div>

            </div>
        </Router>
    );
}

export default App;

import * as React from "react"
import { SVGProps } from "react"
const SmallDoctorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={55}
        height={55}
        fill="none"
        {...props}
    >
        <rect width={55} height={55} fill="#FFF5EF" rx={8} />
        <path
            fill="#FF5D00"
            fillRule="evenodd"
            d="M39.111 19.111c0 6.137-4.974 11.111-11.111 11.111-6.137 0-11.111-4.974-11.111-11.11C16.889 12.973 21.863 8 28 8c6.137 0 11.111 4.974 11.111 11.111Zm-2.222 0a8.889 8.889 0 1 1-17.778 0 8.889 8.889 0 0 1 17.778 0ZM21.238 33.394l.012.025.083.163h13.055c.254-.482.83-1.25 1.39-1.123 1.255.285 2.52.683 3.734 1.173l.037-.018.012.025.009.017c4.572 1.857 8.43 5.006 8.43 8.31V48H8v-6.033c0-4.195 6.218-8.141 12.222-9.508.49-.111.781.467 1.016.935Zm16.433 1.92a22.665 22.665 0 0 0-1.445-.452l-.496.942H19.992l-.46-.874-.4.12a3.558 3.558 0 0 0-.018.249 8.99 8.99 0 0 0 .389 2.723 3.333 3.333 0 1 1-2.174.494l-.009-.032a11.203 11.203 0 0 1-.33-1.584 11.091 11.091 0 0 1-.093-1.031c-1.374.587-2.655 1.295-3.728 2.078-2.136 1.56-2.947 3.017-2.947 4.02v3.81h35.556v-3.81c0-1.004-.811-2.461-2.947-4.02a18.406 18.406 0 0 0-2.962-1.735c-.045.602-.137 1.2-.276 1.788h.63a1.11 1.11 0 0 1 .993.614l1.11 2.223c.078.154.118.324.118.496v2.223a1.11 1.11 0 0 1-1.11 1.11H39.11v-2.222h1.111v-.848l-.686-1.374h-3.072l-.686 1.374v.848h1.11v2.223h-2.221a1.111 1.111 0 0 1-1.111-1.111v-2.223c0-.172.04-.342.117-.496l1.111-2.223a1.111 1.111 0 0 1 .994-.614h1.513c.013-.044.028-.089.041-.138.105-.356.198-.8.261-1.262a7.81 7.81 0 0 0 .078-1.286Zm-17.449 6.02a1.12 1.12 0 0 1-1.11 1.127A1.12 1.12 0 0 1 18 41.333a1.12 1.12 0 0 1 1.111-1.127 1.12 1.12 0 0 1 1.111 1.127Z"
            clipRule="evenodd"
        />
    </svg>
)
export default SmallDoctorIcon

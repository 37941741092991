import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Illustration3 from './assets/images/illustrationdoctor.png'
import Illustration2 from './assets/images/illustrationuserss.png'
import Illustration1 from './assets/images/illustrationuser.png'
const ImageSlider = () => {
    const settings = {
        dots: true,          // Display dots below the slider
        infinite: true,      // Infinite loop sliding
        speed: 500,          // Transition speed
        slidesToShow: 1,     // Number of slides to show
        slidesToScroll: 1    // Number of slides to scroll
    };

    // Sample data: replace these with your actual image URLs
    const imageCards = [
        {imageUrl:Illustration1,textHeader:'Step 1',text:'Let Us Get You Know'},
        {imageUrl:Illustration2,textHeader:'Step 2',text:'Find the best consultation for your needs'},
        {imageUrl:Illustration3,textHeader:'Step 3',text:'Regain your health with expert doctors'}
        // ... add more images as needed
    ];

    return (
        <>
            <div>
                {/* Slider for sizes up to medium */}
                <div className="lg:hidden">
                    <Slider {...settings}>
                        {imageCards.map((card, index) => (
                            <div key={index} className="relative illustration-box">
                                <img src={card.imageUrl} alt={`Slide ${index}`} className=""/>
                                <div className={"text-steps"}>
                                    <div className="">{card.textHeader}</div>
                                    <div className="">{card.text}</div>
                                </div>

                            </div>
                        ))}
                    </Slider>
                </div>

                {/* Side-by-side cards for sizes above medium */}
                <div className="hidden lg:flex  gap-32 lg:gap-0 lg:justify-between items-end items-center">
                    {imageCards.map((card, index) => (
                        <div key={index} className="illustration-box  mx-2 lg:mx-0">
                            <img src={card.imageUrl} alt={`Slide ${index}`} className=""/>
                            <div className={"text-steps"}>
                                <div className="">{card.textHeader}</div>
                                <div className="">{card.text}</div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ImageSlider;

import React from 'react';
import './HowDokitariWorks.css'
import ImageSlider from "./ImageSlider";


const HowDokitariWorks = () => {
    return (
        <div  className={"flex flex-col px-8"} style={{background: 'linear-gradient(180deg, #F4F6F3 0%, #F3E4DD 57.81%, #F4F6F3 100%)'
        }} >
            <h2 className={"headerText text-center lg:text-start"}>
                HOW <span style={{fontWeight:700}}>DOKITARI WORKS</span>
            </h2>
            <ImageSlider/>
            <p className={"weighted-text hidden lg:grid"}>Fill in your information to help us learn about you.</p>
        </div>
    );
};

export default HowDokitariWorks;
import * as React from 'react';
import './Header.css';
import DokitariLogoHeader from "./assets/svg/DokitariLogoHeader";
import {useState} from "react";
import IconForDrawer from "./assets/svg/ExpandDrawerIcon";
import SideDrawer from "./SideDrawer";
import DokitariLogoMd from "./assets/svg/DokitariLogoMD";
import {useNavigate} from "react-router-dom";

interface HeaderProps {
    scrollToSection: (section: string) => void;
}

const Header: React.FC<HeaderProps> = ({scrollToSection}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleRedirectToProviders = () => {
        window.location.href="https://www.providers.dokitari.com"
    };
    const handleDirectToAboutUs = () => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById('AboutUs');
            if (element) element.scrollIntoView({behavior: 'smooth'});
        }, 0);
    };

    function handleDirectToContactUs() {
        navigate('/ContactUs')
    }

    return (
        <header className={"header  flex justify-between content-center px-5 py-4"}>
            <a href={'/'} className={"logoContainer self-center"}>
                <DokitariLogoHeader className={"hidden lg:block"}/>
                <DokitariLogoMd className={"block lg:hidden "}/>
            </a>
            <div className={""}>
                <div className={"hidden md:grid-cols-3 md:grid"}> {/*md:grid-cols-3 */}{/* later it will be added*/}
                    <button className={"buttonHeader"} onClick={handleRedirectToProviders}>Providers App</button>
                    <button className={"buttonHeader"} onClick={handleDirectToAboutUs}>About</button>
                    <button className={"buttonHeader"} onClick={handleDirectToContactUs}>Contact</button>
                </div>
                <button onClick={() => setIsDrawerOpen(true)} className="md:hidden p-2 rounded-md ">
                    <IconForDrawer/>
                </button>
            </div>
            <SideDrawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                handleDirectToAboutUs={handleDirectToAboutUs}
                handleDirectToContactUs={handleDirectToContactUs}
            />
        </header>
    );
}

export default Header;

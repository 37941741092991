import React from 'react';
import DokitariPreviewImage from "./assets/svg/DokitariPreviewImage";
import DokitariPreviewImageSM from "./assets/images/DokitariPreviewImageSM.png"
import AppStoreImage from "./assets/svg/AppStoreImage";
import PlayStoreImage from "./assets/svg/PlayStoreImage";
import './DokitariPreview.css'
import {getDeepLinkURL} from "./AppDownloadLink";

const DokitariPreview = () => {
    const directToDownload = () => {
        window.location.href = getDeepLinkURL();
    }
    return (
        <div style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}} className={"lg:grid lg:grid-cols-2 px-6"}>

                <div className={"content-center items-center self-center text-center lg:items-start lg:text-start lg:content-start py-10"}>
                    <h1 className={"headerText"}>
                        MEET <span style={{fontWeight:'700'}}>DOKITARI</span>
                    </h1>

                    <p  className={"textMultiline"} >
                        Would you like to monitor your health more closely and access consultation whenever you want?
                    </p>
                    <div className={"basicText text-center lg:text-start"} style={{marginBottom:55}}>
                        <div className={"text-center lg:text-start"}>
                            Make an <a  href={getDeepLinkURL()} className={"linkedText"}>appointment</a> {/*link to patient app if user has no patient app downloaded it should lead to download page
                        // will be added later*/}
                            with <a href={getDeepLinkURL()} className={"linkedText"}> one click!</a>
                        </div>
                    </div>
                        <button className={"buttonConsult"} onClick={directToDownload}>
                            <div className={"basicText"} style={{margin:0,color:'#FFFFFF'}}>Consult Now</div>
                        </button>
                </div>
                <div className={"imageGroup"}>
                    <div className={""}>
                        <DokitariPreviewImage className={"hidden md:block"}/>
                        <img className={"md:hidden"} src={DokitariPreviewImageSM} alt={"for small size preview"}/>
                    </div>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                        <a href={'https://apps.apple.com/ph/app/dokitari-health/id1543698473'} target="_blank" rel="noopener noreferrer">
                            <AppStoreImage/>
                        </a>
                        <a href={getDeepLinkURL()} target="_blank" rel="noopener noreferrer">
                            <PlayStoreImage/>
                        </a>
                    </div>
                </div>

        </div>
    );
};


export default DokitariPreview;
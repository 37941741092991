import * as React from "react"
import { SVGProps } from "react"
const MaleDoctorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={86}
        height={98}
        fill="none"
        {...props}
    >
        <rect width={86} height={98} fill="#FFF5EF" rx={15} />
        <path
            fill="#FF5D00"
            fillRule="evenodd"
            d="m31.307 25.898.09-.39c.17-.725.45-1.79 1.027-2.84.588-1.07 1.5-2.154 2.93-2.867 1.424-.711 3.211-.977 5.42-.683 2.437.325 7.335 1.131 11.66 3.332 4.352 2.214 8.427 6.02 8.427 12.362 0 3.277-1.268 6.792-2.495 9.084-.59 1.103-1.292 2.155-1.993 2.723-.16.13-.389.292-.675.407-1.62 5.337-6.578 9.222-12.444 9.222-5.417 0-10.06-3.313-12.015-8.024l-.04.006-.576-.7 1.255-1.034-1.255 1.034-.002-.003-.003-.004-.009-.01-.027-.034a11.761 11.761 0 0 1-.401-.53 21.87 21.87 0 0 1-.99-1.502c-.764-1.265-1.688-3.066-2.276-5.167-.589-2.102-.86-4.565-.241-7.107.602-2.474 2.02-4.91 4.59-7.083l.043-.192Zm2.73 20.54a9.755 9.755 0 0 0 18.795-1.354l.182.061a3.93 3.93 0 0 1-.009-.274c0-2.691-.373-4.57-.722-5.738-.077-.26-.153-.485-.224-.677l-.068.003-.065.002c-.865.025-2.092-.02-3.627-.278-2.79-.471-6.57-1.648-11.019-4.373a9.957 9.957 0 0 0-.291.736c-.32.903-.582 1.973-.808 3.103-.2.997-.365 1.999-.52 2.94l-.06.37c-.167 1.009-.335 1.997-.53 2.714-.34 1.246-.689 2.13-1.034 2.764Zm-2.274-3.027c-.614-1.066-1.283-2.454-1.718-4.008-.485-1.731-.664-3.608-.213-5.461.439-1.804 1.5-3.702 3.684-5.498.393-.267.572-.625.62-.721l.001-.002c.103-.206.166-.414.204-.547.052-.186.109-.437.161-.667l.059-.255c.154-.655.355-1.368.712-2.02.347-.63.823-1.17 1.531-1.523.713-.355 1.814-.599 3.54-.369 2.362.315 6.804 1.067 10.617 3.007 3.786 1.926 6.65 4.841 6.65 9.465 0 2.13-.716 4.55-1.577 6.462a19.629 19.629 0 0 0-.636-3.07 12.44 12.44 0 0 0-.626-1.685 7.33 7.33 0 0 0-.308-.593l-.028-.044-.01-.017-.005-.007-.003-.005s0-.002-1.367.879l1.366-.88-.6-.932-1.077.217-.022.004a8.294 8.294 0 0 1-.886.071c-.664.02-1.678-.013-2.992-.235-2.62-.442-6.472-1.647-11.15-4.763l-1.138-.758-.941.99c-.776.816-1.303 1.93-1.687 3.015-.393 1.113-.69 2.355-.93 3.55-.212 1.056-.386 2.117-.54 3.053l-.06.366c-.175 1.057-.317 1.865-.46 2.388a17.73 17.73 0 0 1-.171.593Z"
            clipRule="evenodd"
        />
        <path
            fill="#FF5D00"
            fillRule="evenodd"
            d="M33.363 57.637c-.344-.686-.768-1.533-1.485-1.37C23.093 58.268 14 64.04 14 70.176V79h58.508v-8.825c0-4.833-5.643-9.44-12.331-12.156l-.007-.015a2.746 2.746 0 0 0-.023-.046l-.054.027a36.195 36.195 0 0 0-5.462-1.717c-.818-.187-1.662.938-2.033 1.643H33.503c-.046-.086-.092-.179-.14-.274Zm21.922 2.146c.71.196 1.418.417 2.116.661.019.556-.023 1.212-.115 1.881-.092.676-.229 1.324-.381 1.847-.02.07-.041.138-.062.201h-2.212c-.616 0-1.179.348-1.454.898l-1.625 3.251a1.624 1.624 0 0 0-.172.727v3.25c0 .898.728 1.626 1.625 1.626h3.25v-3.25h-1.624v-1.243l1.004-2.008h4.492l1.005 2.008v1.242h-1.626v3.25h3.25c.898 0 1.626-.727 1.626-1.625v-3.25c0-.252-.059-.501-.172-.727l-1.625-3.25a1.625 1.625 0 0 0-1.453-.899h-.921a17.338 17.338 0 0 0 .403-2.614c1.585.75 3.058 1.607 4.333 2.537 3.125 2.28 4.31 4.412 4.31 5.879v5.575H17.25v-5.575c0-1.467 1.186-3.599 4.311-5.879 1.57-1.145 3.443-2.182 5.452-3.04.021.503.07 1.014.137 1.508.111.814.279 1.622.482 2.318l.013.046a4.876 4.876 0 1 0 3.18-.721 8.213 8.213 0 0 1-.073-.235 13.168 13.168 0 0 1-.381-1.847 11.398 11.398 0 0 1-.113-1.903c.005-.136.013-.257.024-.364.195-.06.39-.118.586-.175l.673 1.278H54.56l.725-1.378ZM30.252 70.898c.875 0 1.626-.716 1.626-1.65 0-.932-.75-1.648-1.626-1.648-.875 0-1.625.716-1.625 1.649s.75 1.649 1.625 1.649Z"
            clipRule="evenodd"
        />
    </svg>
)
export default MaleDoctorIcon

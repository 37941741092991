import React from 'react';
import './index.css'
const PrivacyPolicy:React.FC = () => {
    return (
        <div className={"flex flex-col main"}>
            <div className={" page-header px-9 py-8"} style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}}>
                <h1 className={"text-center"}>PRIVACY POLICY</h1>
            </div>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-start-3 col-span-8 p-4 text-justify">
                    <div className={"title-text"}>
                        <p className={"mb-2"}>WYNN HEALTH GROUP LIMITED</p>
                        <p className={"mb-2"}>Dokitari Mobile Clinic Privacy Policy</p>
                    </div>


                    <h4 className={"font-bold text-xl mb-2"}>POLICY: APP PRIVACY POLICY – Managing patient Health information
                    </h4>
                    <h4 className={"font-bold text-xl mb-2"}>1. POLICY</h4>
                    <p className={"mb-2"}>
                        The management of Dokitari mobile clinic are committed to protecting the privacy clients.
                        Information
                        collected is kept strictly confidential and used only for the medical and health care of
                        patients.
                    </p>
                    <h4 className={"font-bold text-xl mb-2"}>2. PURPOSE</h4>
                    <p className={"mb-2"}>
                        To ensure patients who consult on our platform are comfortable in entrusting their health
                        information to
                        the Doctors. This policy provides information to patients as to how their personal information is
                        collected
                        and used on the platform and the circumstances in which we may disclose it to third parties.
                    </p>
                    <p className={"font-bold text-xl mb-2"}>3. SCOPE</p>
                    <p className={"mb-2"}>
                        This policy applies to all Doctor partners, employees and patients of Dokitari mobile clinic.
                    </p>
                    <p className={"font-bold text-xl mb-2"}>4. PRACTICE PROCEDURE</p>
                    <p className={"mb-2"}>The Platform will:
                    </p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>Provide a copy of this policy upon request
                        </li>
                        <li className={" mb-1"}>Ensure staff comply with the APP and deal appropriately with inquiries or concerns</li>
                        <li className={" mb-1"}>Take such steps as are reasonable in the circumstances to implement practices, procedures and
                            systems to ensure compliance with the APP and deal with inquiries or complaints
                        </li>
                        <li className={" mb-1"}>Collect personal information for the primary purpose of managing a patient’s healthcare and for
                            financial claims and payments
                        </li>
                    </ul>
                    <p className={"mb-2"}><strong>Staff Responsibility</strong>
                        The health professionals will take reasonable steps to ensure patients understand</p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>What information has been and is being collected
                        </li>
                        <li className={" mb-1"}>Why the information is being collected and whether this is due to a legal requirement
                        </li>
                        <li className={" mb-1"}>How the information will be used or disclosed</li>
                        <li className={" mb-1"}>Why and when their consent is necessary</li>
                        <li className={" mb-1"}>The Practice’s procedures for access and correction of information, and responding to complaints
                            of
                            information breaches, including by providing this policy
                        </li>
                    </ul>
                    <p className={"mb-2"}><strong>Patient Consent</strong></p>
                    <p className={"mb-2"}>The platform will only interpret and apply a patient’s consent for the primary purpose for which it
                        was
                        provided. The Platform staff must seek additional consent from the patient if the personal
                        information
                        collected may be used for any other purpose.</p>
                    <p className={"font-bold text-xl mb-2"}>5. COLLECTION, USE AND DISCLOSURE</p>
                    <p className={"mb-2"}>Dokitari mobile clinic recognises that the information we collect is often of a highly sensitive
                        nature and
                        as an organisation we have adopted the privacy compliance standards relevant to Dokitari mobile
                        clinic to
                        ensure personal information is protected.
                        For administrative and billing purposes and to ensure quality and continuity of patient care a
                        patient’s
                        health information is shared between the medical practitioners of Dokitari mobile clinic.

                        Collected personal information will include patient’s
                    </p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>Names, addresses and contact details
                        </li>
                        <li className={" mb-1"}>Medicare number for identification and claiming purposes</li>
                        <li className={" mb-1"}>Healthcare identifiers</li>
                        <li className={" mb-1"}>Medical information including medical history, medications, allergies, adverse events,
                            immunisations, social history, family history and risk factors.
                        </li>
                    </ul>

                    <p className={"mb-2"}></p>
                    <p className={"mb-2"}> A patient’s personal information may be held at Dokitari in various forms</p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>As paper records</li>
                        <li className={" mb-1"}>As electronic records</li>
                        <li className={" mb-1"}>As visuals i.e xrays, ct scans, videos &amp; photos</li>
                        <li className={" mb-1"}>As audio recordings</li>
                    </ul>
                    <p className={"mb-2"}> Dokitari mobile clinic’s procedures for collecting personal information is set out below:</p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>Practice staff collect patient’s personal and demographic information via registration when
                            patients
                            present to the clinic for the first time. Patients are encouraged to pay attention to the
                            collection
                            statement that they complete as a new patient
                        </li>
                        <li className={" mb-1"}>During the course of providing medical services the Dokitari’s healthcare practitioners will
                            consequently collect further personal information
                        </li>
                        <li className={" mb-1"}>Personal information may also be collected from the patient’s guardian or responsible person
                            (where
                            practicable and necessary) or from other involved healthcare specialists.
                        </li>
                    </ul>
                    <p className={"mb-2"}>Dokitari mobile clinic holds all personal information securely, whether in electronic format, in
                        protected
                        information systems or in hard copy in a secured environment.
                        Personal information collected by Dokitari mobile clinic may be used or disclosed in the
                        following
                        instances:
                    </p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>For medical defence purposes</li>
                        <li className={" mb-1"}>As required by law in instances of mandatory reporting of communicable diseases</li>
                        <li className={" mb-1"}>Necessary to lessen or prevent a serious threat to a patient’s life, health or safety or
                            public health
                            or safety, or it is impracticable to obtain patient’s consent
                        </li>
                        <li className={" mb-1"}>To assist in locating a missing person</li>
                        <li className={" mb-1"}>For the purpose the patient was advised during consult with the consulting Doctor;</li>
                        <li className={" mb-1"}>As required during the normal operation of services provided. i.e. for referral to a medical
                            specialist
                            or another health service provider;
                        </li>
                        <li className={" mb-1"}>For the purpose of a confidential dispute resolution process</li>
                        <li className={" mb-1"}>Some disclosure may occur to third parties engaged by or for the practice for the Practice
                            for business
                            purposes such as accreditation or for the provision of information technology. These third
                            parties are
                            required to comply with this policy.
                        </li>
                    </ul>
                    <p className={"mb-2"}>
                        Dokitari mobile clinic will not disclose personal information to any third party other than in
                        the course
                        of providing medical services, without full disclosure to the patient or the recipient, the
                        reason for the
                        information transfer and full consent from the patient.

                        Dokitari mobile clinic evaluates all unsolicited information it receives to decide if it
                        should be kept,
                        acted upon or destroyed.

                        Dokitari mobile clinic will employ all reasonable endeavours to ensure that a patient’s
                        personal
                        information is not disclosed without their prior consent.
                    </p>
                    <p className={"font-bold text-xl mb-2"}>6. DATA QUALITY</p>
                    <p className={"mb-2"}>Patient information collected and retained in our records for the purpose of providing quality
                        health care
                        will be complete, accurate, and up to date at the time of collection. Doctors are reminded to
                        review past
                        medical history at least every 3 years.</p>
                    <p className={"font-bold text-xl mb-2"}>7. DATA SECURITY</p>
                    <p className={"mb-2"}>All due care will be taken to ensure the protection of patient privacy during the transfer,
                        storage and use
                        of personal health information.</p>
                    <p className={"font-bold text-xl mb-2"}>8. ACCESS TO PATIENT INFORMATION AND CORRECTION</p>
                    <p className={"mb-2"}>The following will apply with regard to accessing personal and private medical information by an
                        individual:</p>
                    <ul className={" space-y-1  list-disc list-inside mb-2"}>
                        <li className={" mb-1"}>An individual has the right to request access their own personal information and request a
                            copy or part
                            of the whole record
                        </li>
                        <li className={" mb-1"}>Individuals have the right to obtain their personal information. Requests must be made in
                            writing and
                            an acknowledgement letter will be sent to the patient within 14 days confirming the request
                            and
                            detailing whether the request can be complied with and an indication of any costs associated
                            with
                            providing the information. Time spent and photocopying costs when processing a request can
                            be passed on
                            to the requesting patient. Information can be expected to be provided within 30 days
                        </li>
                        <li className={" mb-1"}>Whilst the individual is not required to give a reason for obtaining the information, a
                            patient may be
                            asked to clarify the scope of the request;
                        </li>
                        <li className={" mb-1"}>In some instances the request to obtain information may be denied, in these instances the
                            patient will
                            be advised;
                        </li>
                        <li className={" mb-1"}>The material over which a Doctor has copyright might be subject to conditions that
                            prevent or restrict further copying or publication without the Doctors permission;
                        </li>
                        <li className={" mb-1"}>Dokitari mobile clinic will take reasonable steps to correct personal information where it
                            is satisfied
                            they are not accurate or up to date. From time to time the practice will ask patients to
                            verify the
                            personal information held by the practice is correct and up to date.
                        </li>
                        <li className={" mb-1"}>Patients may also request the Practice corrects or updates their information and patients
                            should must
                            such requests in writing
                        </li>
                        <li className={" mb-1"}>Upon request by the patient, the information held by this clinic will be made available to
                            another
                            health provider.
                        </li>
                    </ul>
                    <p className={"font-bold text-xl mb-2"}>9. PARENTS/GUARDIANS AND CHILDREN</p>
                    <p className={"mb-2"}>To protect the rights of a child’s privacy, access to a child’s medical information may at times
                        be
                        restricted for parents and guardians. Release of information may be referred back to the
                        treating Doctor
                        where their professional judgement and the law will be applied.</p>
                    <p className={"font-bold text-xl mb-2"}>10. COMPLAINTS</p>
                    <p className={"mb-2"}>The management of Dokitari mobile clinic understands the importance of confidentiality and
                        discretion with
                        the way we manage and maintain the personal information of our patients. The platform takes
                        complaints and
                        concerns about the privacy of patient’s personal information seriously. Patients should express
                        any privacy
                        concerns in writing. The Practice will then attempt to resolve it in accordance with its
                        complaint
                        resolution process.
                        All employees of Dokitari mobile clinic are required to observe the obligations of
                        confidentiality in the
                        course of their employment and are required to sign Confidentiality Agreements.
                        In the instance where you are dissatisfied with the level of service provided within the
                        clinic we
                        encourage you to discuss any concerns relating to the privacy of your information with the
                        Platform Manager
                        or your Doctor.
                        If the complaint has not been resolved to your level of satisfaction all complaints should
                        be directed to:<strong>
                            hellodoctari@dokitari.com</strong>
                    </p>

                </div>

            </div>
        </div>

    );
};



export default PrivacyPolicy;
import * as React from "react"
import { SVGProps } from "react"
const LikeButton = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={24}
        fill="none"
        {...props}
    >
        <path
            fill="#979CAC"
            d="M3 23.75h1.25V7.5H3A2.5 2.5 0 0 0 .5 10v11.25a2.5 2.5 0 0 0 2.5 2.5ZM23 7.5h-8.75l1.403-4.21A2.499 2.499 0 0 0 13.28 0h-.28L6.75 6.798V23.75H20.5l4.89-10.745.11-.505V10A2.5 2.5 0 0 0 23 7.5Z"
        />
    </svg>
)
export default LikeButton

import React, {useEffect, useState} from 'react';
import './index.css'
import CardList from "./CardList";
import LikeButton from "./assets/svg/LikeButton";
import DislikeButton from "./assets/svg/DislikeButton";
import {useParams} from "react-router-dom";
import {GetFeed, GetMarkdown} from "./apis/feed";
import {Oval} from "react-loader-spinner";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {decode_b64_to_utf8, encode_utf8_to_b64} from "./utils";


const Article = () => {
    const {id} = useParams()
    const {feed,isLoading} = GetFeed(id)

    if (isLoading){
        return <div className={"main flex"}>

            <Oval secondaryColor={'#F4F4F4'} color={'#FFF'} />

        </div>
    }
    const feedContentToBeDecoded = feed?.description
    const decodedString = decode_b64_to_utf8(feedContentToBeDecoded)

    return (
        <div className={"main"} style={{background:'#F4F6F3'}}>
            <div className={" page-header px-9 py-8"}
                 style={{background: 'linear-gradient(257deg, #F3E5DB 0%, #F5DFE4 32.81%, #E8E0EF 60.94%, #F4EFDC 100%)'}}>
                <p className={"text-left "}>{feed.title}</p>
            </div>
            <div className="grid lg:grid-cols-8 xl:grid-cols-12 grid-cols-1 gap-4 p-5">

                <div className="grid-cols-1 lg:col-start-2 lg:col-span-6 xl:col-start-4 xl:col-span-6  text-justify content-center">
                    <img className="rounded-t-lg mb-5  w-full " src={feed?.thumbnailUrl}  alt={"Valley"}/>
                    {/*<img className="rounded-t-lg mb-5  w-full " src={feed?.videoUrl.length === 0 ? feed?.thumbnailUrl : feed?.videoUrl}  alt={"Valley"}/>*/}
                    <div className="text-only lg:p-4 p-2">
                        <div className={"lg:flex lg:justify-between grid grid-cols-1 justify-start"}>
                            <p className={"font-bold text-3xl mb-2 header-family"}>{}</p>
                            {/*<p className={"text-xs font-light self-center"}>{feed?.author} {feed?.dateCreated}</p>*/}
                        </div>
        <article className="prose prose-slate">
            <Markdown children={decodedString} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}/>
        </article>
                    </div>
                    <div className={"self-start lg:p-4 p-2 mt-2"}>
                        <div className={"flex gap-7 mb-5 self-center "}>
                            <div className={"flex gap-2"}>
                                <LikeButton/>
                                <p className={"font-semibold"}>{feed?.likes}</p>
                            </div>
                            <div className={"flex gap-2 h-6"}>
                                <DislikeButton style={{paddingTop:3}}/>
                                <p className={"font-semibold"}>{feed?.dislikes}</p>
                            </div>

                        </div>
                        <button className={"buttonShare"} onClick={()=>{}}>
                            <div className={"basicText"} style={{margin:0,color:'#FFFFFF'}}>Share</div>
                        </button>
                    </div>


                </div>

            </div>
            <div className={"mb-10"}>
                <p className={"headerText text-lg mb-2 text-center mt-3"}>
                    SIMILAR <span style={{fontWeight:'700'}}> ARTICLES</span>
                </p>
                <CardList/>
            </div>


        </div>
    );
};

export default Article;
import * as React from "react"
import { SVGProps } from "react"
const SmallGraphIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={55}
        height={55}
        fill="none"
        {...props}
    >
        <rect width={55} height={55} fill="#F6EEFF" rx={8} />
        <path
            fill="#731DD8"
            fillRule="evenodd"
            d="M9.143 47A1.143 1.143 0 0 1 8 45.857V7h2.286v37.714h3.428V27.571c0-.63.512-1.142 1.143-1.142h6.857c.632 0 1.143.511 1.143 1.142v17.143h2.286V23c0-.631.511-1.143 1.143-1.143h6.857c.631 0 1.143.512 1.143 1.143v21.714h2.285V13.857c0-.631.512-1.143 1.143-1.143h6.857c.632 0 1.143.512 1.143 1.143v30.857H48V47H9.143Zm34.286-2.286V15h-4.572v29.714h4.572Zm-11.429 0h-4.571V24.143H32v20.571Zm-11.429 0v-16H16v16h4.571Z"
            clipRule="evenodd"
        />
    </svg>
)
export default SmallGraphIcon

import React from 'react';
import CardComponent from './CardComponent';
import MaleDoctorIcon from "./assets/svg/MaleDoctorIcon";
import ReportIcon from "./assets/svg/ReportIcon";
import './CardComponent.css'
import FemaleDoctor from "./assets/svg/FemaleDoctor";
import DocumentIcon from "./assets/svg/DocumentIcon";

const CardSection: React.FC = () => {
    return (
        <div  className={"xl:px-3 xl:py-2 px-6 py-3 "}>
            <h2 className={'headerText text-center xl:text-left px-6'} >
                OUR <span style={{fontWeight:'700'}}> SERVICES</span>
            </h2>
            <div className={"grid grid-cols-1  md:grid-cols-2 xl:grid-rows-1 xl:grid-cols-4 gap-4 px-6"}>
                <CardComponent
                    icon={<MaleDoctorIcon/>}
                    textHeader="Wide Range of Expertise"
                    text="Cardiology, Dermatology, Pediatrics, Ophthalmology, and more."
                />
                <CardComponent
                    icon={<ReportIcon/>}
                    textHeader="Online and In-Person Appointments"
                    text="We offer the flexibility of online and in-person appointments for your healthcare needs."
                />
                <CardComponent
                    icon={<FemaleDoctor/>}
                    textHeader="Doctor Profiles and Specialties"
                    text="Get insights into doctors' educational backgrounds and experiences."
                />
                <CardComponent
                    icon={<DocumentIcon/>}
                    textHeader="Privacy and Security"
                    text="Your personal health information is kept confidential and secure."
                />
            </div>
        </div>

    );
}

export default CardSection;

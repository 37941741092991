import * as React from "react"
import { SVGProps } from "react"
const ReportIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={86}
        height={98}
        fill="none"
        {...props}
    >
        <rect width={86} height={98} fill="#F6EEFF" rx={15} />
        <path
            fill="#731DD8"
            fillRule="evenodd"
            d="M32.5 23.5A4.5 4.5 0 0 1 37 19h12a4.5 4.5 0 0 1 4.5 4.5h6A4.5 4.5 0 0 1 64 28v46.5a4.5 4.5 0 0 1-4.5 4.5h-33a4.5 4.5 0 0 1-4.5-4.5V28a4.5 4.5 0 0 1 4.5-4.5h6Zm0 3h-6A1.5 1.5 0 0 0 25 28v16.483h8.449l5.082-9.035 3.175 11.114 3.887-4.996h8.233a1.5 1.5 0 0 1 0 3H47.06l-6.614 8.504-2.825-9.886-2.418 4.299H25V74.5a1.5 1.5 0 0 0 1.5 1.5h33a1.5 1.5 0 0 0 1.5-1.5V28a1.5 1.5 0 0 0-1.5-1.5h-6A4.5 4.5 0 0 1 49 31H37a4.5 4.5 0 0 1-4.5-4.5ZM37 22a1.5 1.5 0 0 0-1.5 1.5v3A1.5 1.5 0 0 0 37 28h12a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 49 22H37Zm-6 37.5a1.5 1.5 0 0 1 1.5-1.5h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1-1.5-1.5Zm0 9a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 0 3h-9a1.5 1.5 0 0 1-1.5-1.5Z"
            clipRule="evenodd"
        />
    </svg>
)
export default ReportIcon

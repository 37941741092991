import React from 'react';
import Slider from 'react-slick';
import PhotoCardComponent from "./PhotoCardComponent";
import {GetFeeds} from "./apis/feed";
import {Oval} from "react-loader-spinner";


type CardProps = {
    header: string;
    link?:string;
    text:string;
    date:string;
    src:string;
};



const CardList: React.FC = () => {
    const { feeds,isLoading } = GetFeeds()
    console.log("description",feeds[4]?.description)
    console.log(feeds,"feeds line 18")
    if(isLoading){
        return <Oval secondaryColor={'#F4F4F4'} color={'#FFFF'} />
    }
    const width = window.innerWidth
    let slidesToShow;
    if (width < 640) {
        slidesToShow = 1;
    } else if (width < 768) {
        slidesToShow = 2;
    } else if (width < 1200){
        slidesToShow = 3;
    }else if (width < 1500) {
        slidesToShow = 4
    }else slidesToShow = 5

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: true,
        marginBottom:10
    };

    const cards :(CardProps)[]  = [
        { text: 'Use this tool to calculate your ideal weight' ,header:'Ideal weight calculator',date:" 10.12.2022",src:'https://dokitari.com/img/livewell/ideal-weight.png',link:'/Articles'},
        { text: 'What should my daily intake of calories be? An ideal daily intake of calories varies depending on age, metabolism and levels of physical activity, among other things. Generally, the recommended daily calorie intake is 2,000 calories a day for women and 2,500 for men' ,header:'Calorie calculator',date:'21.12.2022',src:'https://dokitari.com/img/livewell/calorie-calculator.png'},
        { text: "Diabetes is a lifelong condition that causes a person's blood sugar level to become too high" ,header:'Diabetes',date:'Emily Clark 24.12.2022',src:'https://dokitari.com/img/livewell/diabetes.png'},
        { text: 'Use this calculator to check your body mass index (BMI) and find out if you\'re a healthy weight. Or you can use it to check your child\'s BMI.' ,header:'BMI calculator',date:'Emily Clark 29.12.2022',src:'https://dokitari.com/img/livewell/bmi.png'},
        { text: 'The calculator on this page can help you work out when you might expect your baby to arrive.' ,header:'Due date calculator',date:'09.02.2023',src:'https://dokitari.com/img/livewell/dueDate.png'},


    ];


    return (
        feeds.length === 0 ? (
            <div className={"bg-amber-100 "}>
                <p className={"font-semibold text-xl"}>Our articles temporarily unavailable</p>
            </div>
        ) : (
            <Slider {...settings}>
                {feeds.map((card, index) => (
                    <a href={`/Articles/${card.id}`} key={index}>
                        <PhotoCardComponent
                            photoSrc={card.thumbnailUrl}
                            textHeader={card.title}
                            text={card.content}
                            date={card.dateCreated}
                        />
                    </a>
                ))}
            </Slider>
        )
    );
};

export default CardList;

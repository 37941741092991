import React from 'react';
import CardList from "./CardList";
const OurArticles = () => {
    return (
        <div className={"px-8"} style={{marginTop:50,marginBottom:50}}>
            <h2 className={'headerText'} >
                OUR <span style={{fontWeight:'700'}}> ARTICLES</span>
            </h2>
           <CardList />
        </div>

    );
};

export default OurArticles;
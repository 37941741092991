import React, { useRef, useEffect } from 'react';
import {useNavigate} from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    handleDirectToAboutUs: any;
    handleDirectToContactUs:any;
}

const SideDrawer: React.FC<Props> = ({ isOpen, onClose,handleDirectToContactUs,handleDirectToAboutUs }) => {
    const drawerRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate()
    const handleRedirectToProviders = () => {
        window.location.replace("https://www.providers.dokitari.com")
    };
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    return (
        <div
            ref={drawerRef}
            className={`fixed top-0 right-0 w-56 h-full bg-white transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <ul className="space-y-4 p-4">
                <li onClick={handleDirectToAboutUs}>About</li>
                <li onClick={handleDirectToContactUs}>Contact</li>
                <li onClick={handleRedirectToProviders}>Providers App</li>
            </ul>
        </div>
    );
}

export default SideDrawer;

import * as React from "react"
import { SVGProps } from "react"
const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M28.35 0H1.65A1.65 1.65 0 0 0 0 1.65v26.7A1.65 1.65 0 0 0 1.65 30h14.37V18.375h-3.9v-4.5h3.9V10.5a5.46 5.46 0 0 1 5.82-6 30.39 30.39 0 0 1 3.495.18v4.05H22.95c-1.89 0-2.25.9-2.25 2.205v2.895h4.5l-.585 4.5H20.7V30h7.65A1.65 1.65 0 0 0 30 28.35V1.65A1.65 1.65 0 0 0 28.35 0Z"
        />
    </svg>
)
export default FacebookIcon

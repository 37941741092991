import * as React from "react"
import { SVGProps } from "react"
const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M0 2.149C0 .962.986 0 2.203 0h25.594C29.014 0 30 .962 30 2.149V27.85C30 29.038 29.014 30 27.797 30H2.203C.986 30 0 29.038 0 27.851V2.15Zm9.268 22.965V11.567H4.766v13.547h4.502ZM7.018 9.716c1.57 0 2.546-1.038 2.546-2.34-.028-1.33-.975-2.34-2.516-2.34S4.5 6.05 4.5 7.376c0 1.301.977 2.34 2.488 2.34h.03Zm9.203 15.398v-7.566c0-.405.03-.81.15-1.099.324-.808 1.065-1.646 2.31-1.646 1.629 0 2.28 1.241 2.28 3.064v7.247h4.502v-7.77c0-4.163-2.22-6.098-5.183-6.098-2.389 0-3.46 1.313-4.06 2.237v.047h-.03l.03-.047v-1.916h-4.5c.057 1.271 0 13.547 0 13.547h4.5Z"
        />
    </svg>
)
export default LinkedInIcon

import React from 'react';
import './ReasonSection.css';
import FemaleDoctor2WithStrips from './assets/images/FemaleDoctor2WithStrips.png';


const AboutUs = () => {
    const handleScrollToSection = (sectionId: string) => {
        document.getElementById(sectionId)?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <div className="justify-between px-8 grid grid-cols-1 lg:grid-cols-2" id={'AboutUs'}>
            <div style={{ paddingTop: 50, flex: 0.5, flexDirection: 'column' }}>
                <h2 className="headerText text-center lg:text-start">
                    ABOUT <span style={{ fontWeight: 700 }}>US</span>
                </h2>
                <div className=" mb-5 text-justify font-poppins font-xl">
                    Dokitari Health App is your all-in-one healthcare companion, offering virtual doctor consultations, easy hospital appointment booking, informative health articles, and secure medical record management. It's designed to make healthcare accessible, efficient, and user-centric, empowering you to take control of your well-being. Download the app today and make your health your choice. Your well-being is their priority.
                </div>
                <div className="text-center lg:text-start">
                    <button className="buttonConsult mb-6 " onClick={() => handleScrollToSection('getInTouch')}>
                        <div className="basicText" style={{ margin: 0, color: '#FFFFFF' }}>
                            Get in Touch
                        </div>
                    </button>
                </div>
            </div>
            <div style={{ display: 'flex', flex: 0.5, justifyContent: 'center',alignItems:'center' }}>
                <img className={"lg:w-8/12"} src={FemaleDoctor2WithStrips} alt="Doctors with back ground" />
            </div>
        </div>
    );
};

export default AboutUs;

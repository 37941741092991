import * as React from "react"
import { SVGProps } from "react"
const SmallReportIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={55}
        height={55}
        fill="none"
        {...props}
    >
        <rect width={55} height={55} fill="#FFF1F7" rx={8} />
        <path
            fill="#FF006E"
            fillRule="evenodd"
            d="M20.313 11.046a2.983 2.983 0 0 1 3.014-2.952l7.962.075a2.983 2.983 0 0 1 2.958 3.008l3.98.038a2.983 2.983 0 0 1 2.959 3.008l-.29 30.797a2.983 2.983 0 0 1-3.014 2.952l-21.896-.206a2.983 2.983 0 0 1-2.958-3.009l.29-30.796a2.983 2.983 0 0 1 3.014-2.953l3.98.038Zm-.019 1.987-3.98-.038a.994.994 0 0 0-1.006.984l-.102 10.917 5.606.053 3.428-5.952 2.038 7.38 2.61-3.284 5.462.051a.994.994 0 1 1-.018 1.987l-4.49-.042-4.441 5.59-1.813-6.564-1.63 2.832-6.771-.064-.169 17.893a.994.994 0 0 0 .986 1.003l21.896.206c.55.005 1-.435 1.005-.984l.29-30.797a.994.994 0 0 0-.986-1.003l-3.981-.037a2.983 2.983 0 0 1-3.014 2.952l-7.962-.075a2.983 2.983 0 0 1-2.958-3.008Zm3.014-2.952a.994.994 0 0 0-1.005.984l-.018 1.987a.994.994 0 0 0 .986 1.002l7.962.075c.55.005 1-.435 1.004-.984l.02-1.987a.994.994 0 0 0-.987-1.002l-7.962-.075Zm-4.215 24.798a.994.994 0 0 1 1.005-.984l13.934.131a.994.994 0 1 1-.02 1.987l-13.933-.131a.994.994 0 0 1-.986-1.003Zm-.056 5.96a.994.994 0 0 1 1.005-.983l5.971.056a.994.994 0 1 1-.019 1.987l-5.971-.056a.994.994 0 0 1-.986-1.003Z"
            clipRule="evenodd"
        />
    </svg>
)
export default SmallReportIcon

import React from 'react';
import DoctorImageWithStrips from './assets/images/FemaleDoctor1WithStrips.png'
import './ReasonSection.css'
import SmallGraphIcon from "./assets/svg/SmallGraphIcon";
import SmallReportIcon from "./assets/svg/SmallReportIcon";
import SmallDoctorIcon from "./assets/svg/SmallDoctorIcon";
import {getDeepLinkURL} from "./AppDownloadLink";
const ReasonSection = () => {
    const directToDownload = () => {
        window.location.href = getDeepLinkURL();
    }
    return (
        <div style={{justifyContent:'center'}} className={"grid grid-cols-1 md:grid-cols-2 my-10 px-6"}>
            <div className={"lg:justify-self-center"}>
                <img src={DoctorImageWithStrips} alt={'Doctor with back ground'}/>
            </div>
            <div className={"text-center lg:text-start self-center"}>
                <h2 className={"headerText"}>
                    HERE IS THE REASON
                    <p style={{fontWeight:700,margin:0}}>WHY YOU SHOULD CHOOSE US</p>
                </h2>
                <div style={{marginTop:45}} className={""}>
                    <div className={"icon-and-text content-center lg:content-start"}>
                        <div className={"icon-box"}>
                            <SmallGraphIcon/>
                        </div>
                        <div className={"basicText"}>Keep a closer eye on your health</div>
                    </div>
                    <div className={"icon-and-text content-center lg:content-start"}>
                        <div className={"icon-box"}>
                            <SmallReportIcon/>
                        </div>

                        <div className={"basicText"}>Online or face-to-face appointment opportunity</div>
                    </div>
                    <div className={"icon-and-text content-center lg:content-start"}>
                        <div className={"icon-box"}>
                            <SmallDoctorIcon/>
                        </div>
                        <div className={"basicText"}>Immediate access to experienced doctors</div>
                    </div>
                </div>
                <button className={"buttonConsult"} onClick={directToDownload}>
                    <div className={"basicText"} style={{margin:0,color:'#FFFFFF'}}>Consult Now</div>
                </button>
            </div>

        </div>
    );
};

export default ReasonSection;